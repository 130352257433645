var timeleft = 1;
var redirectTimer = setInterval(function(){
  if(timeleft >= 20){
    clearInterval(redirectTimer);
    window.location.href = "/";
  }
  var counting = 20 - timeleft;
  document.getElementById("countRedirect").innerHTML = '('+ counting +')';
  timeleft += 1;
}, 1000);
$(document).ready(function(){
    getOtherArticle();
})
var getOtherArticle = function(){
  var append = '';
  actionWebApiRestrict('/v1/stream/article/recommendation', {'limit':5}, 'GET').done(function(json) {
    if (json.status == 600) {
      if(json.data.length){
          $.each(json.data, function(key, value) {
            append += '<a class="other-list-container" onclick="panelTrackingPushSection(\'Tulisan Lainnya\',\''+value.url+'\',\'Bawah Article\',\''+value.title+'\')" href="'+value.url+'" data-type="'+value.type+'">';
            append += '  <span class="other-list-image">';
            append += '  <img class="lazy-other" src="'+value.cover.small+'" alt="cover image"></span>';
            append += '  <span class="other-list-title"><h2>'+value.richtext_title+'</h2>';
            append += '</a>';
          })
          $('#detail-other-list').html(append)
      }else{
        $('#notFoundOther').html('')
      }
    }
  })
}
var hostname = $(location).attr('protocol') + '//' + $(location).attr('host');
var web_api = hostname + "/web/api";
var actionWebApiRestrict = function(url, params, reqType, header) {
    var data = $.ajax({
        url: web_api + url,
        type: reqType,
        dataType: 'json',
        data: $.param(params, true),
        beforeSend: function(request) {
            request.setRequestHeader('X-User-Agent', 'Web');
            request.setRequestHeader(
                          $("meta[name='_csrf_header']").attr("content"),
                          $("meta[name='_csrf']").attr("content"));
            if(header) {
              $.each(header, function(i, val) {
                request.setRequestHeader(Object.keys(val)[0], val[Object.keys(val)[0]]);
              })
            }
        }
    });
    return data;
};
var firstScroll = true;
$(window).on('scroll', function(){
    if(firstScroll){
        if($(window).scrollTop() > 200){
            firstScroll = false;
            clearInterval(redirectTimer);
            $('#countRedirect').remove();
        }
    }
  })